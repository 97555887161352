.createRole {
  .dropZoneJob {
    min-height: 6em !important;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60%;
    margin: 0 auto;
    .MuiDropzoneArea-icon {
      display: none;
    }

    .MuiDropzoneArea-text {
      font-weight: 500;
      font-size: 1em;
      line-height: 24px;
      text-align: center;
      letter-spacing: 1.06667px;
      text-transform: uppercase;
      color: #8a8a8a;
    }
  }

  .hiringselect {
    .MuiSelect-select {
      &:focus {
        border-radius: 0;
        background-color: rgba(0, 0, 0, 0);
      }
      &.MuiOutlinedInput-input {
        padding: 8.5px 14px;
      }
    }

    fieldset:after {
      left: 0;
      right: 0;
      bottom: 0;
      content: "\00a0";
      position: absolute;
      transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      border-bottom: 1px solid rgba(0, 0, 0, 0.42);
      pointer-events: none;
    }

    fieldset {
      border: none;
    }
  }

  .MuiInputBase-input {
    color: #000000;
  }
}

.createRole-footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.job-description-modal {
  .job-descriptio-box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #f8f8f8;
    border-radius: 10px;
    width: 93%;
    height: 95%;
    .close-jd-div {
      position: absolute;
      right: -0.5em;
      top: -0.5em;
      background: #333333;
      color: #fff;
      border-radius: 50%;
      width: 1.5em;
      height: 1.5em;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 1;
      cursor: pointer;
      svg {
        width: 0.7em;
        height: 0.7em;
      }
    }
    .job-desc-form {
      padding: 1.8125em 0.375em 2.375em;
      height: 93%;
      position: relative;
      .form-data-wrap {
        height: 100%;
        overflow: auto;
        overflow-x: hidden;
        &::-webkit-scrollbar {
          width: 0.2em;
          background: #e2e2ea;
          border-radius: 2.5px;
        }
        &::-webkit-scrollbar-thumb {
          background: #24325f8c;
          border-radius: 2.5px;
        }
        .job-descriptio-step {
          position: relative;
          padding: 0 2em;

          .job-description-title {
            margin: 0 0 2em;
            font-family: "Poppins-SemiBold";
            text-align: center;
            font-size: 1.375em;
          }
          .job-desc-textLabel {
            font-size: 0.75em;
            color: #92929d;
          }
          .jd-placeholder {
            color: #c8c7cb;
          }
          .job-desc-textField {
            .MuiSelect-select {
              &:focus {
                border-radius: 0;
                background-color: rgba(0, 0, 0, 0);
              }
              &.MuiOutlinedInput-input {
                padding: 6px 0;
                height: 1.5em;
                color: #44444f;
                .MuiTypography-body1 {
                  font-size: 0.9em;
                }
              }
            }

            fieldset:after {
              left: 0;
              right: 0;
              bottom: 0;
              content: "\00a0";
              position: absolute;
              transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
              border-bottom: 1px solid rgba(0, 0, 0, 0.42);
              pointer-events: none;
            }

            fieldset {
              border: none;
            }
          }

          .jd-card {
            background: #ffffff;
            border-radius: 0.625em;
            height: 100%;
            .jd-card-title {
              margin: 0;
              text-align: center;
              font-size: 0.875em;
              font-family: "Poppins-SemiBold";
              text-transform: uppercase;
              padding: 1.125em 0;
            }
          }

          .jd-radio-wrap {
            padding: 0.760625em 0.760625em 1.5em 0.760625em;
            .jd-radio-box {
              border: 1px solid #9797972b;
              box-sizing: border-box;
              border-radius: 10px;
              .jd-radio-label {
                font-weight: 600;
                font-size: 11px;
                line-height: 16px;
                text-align: center;
                letter-spacing: 0.8px;
                text-transform: uppercase;
                color: #44444f;
                font-family: "Poppins-Regular";
                background: #dcecf5;
                border-radius: 10px 10px 0px 0px;
                margin: 0;
                padding: 1em;
              }
              .jd-radio-box-wrap {
                padding: 0.5em;
                .MuiFormControlLabel-root {
                  width: 100%;
                }
                .MuiFormGroup-root {
                  height: 14.75em;
                  overflow: auto;
                  overflow-x: hidden;
                  .MuiRadio-colorSecondary.Mui-checked {
                    color: #1e75fe;
                  }
                  &::-webkit-scrollbar {
                    width: 0.2em;
                    background: #e2e2ea;
                    border-radius: 2.5px;
                  }
                  &::-webkit-scrollbar-thumb {
                    background: #24325f8c;
                    border-radius: 2.5px;
                  }
                }
              }
            }
          }
          .jd-main-radio-box-wrap {
            padding: 0 2em 1.5em;
            .MuiFormGroup-root {
              height: 18.75em;
              overflow: auto;
              overflow-x: hidden;
              flex-direction: row;
              .MuiRadio-colorSecondary.Mui-checked {
                color: #1e75fe;
              }
              &::-webkit-scrollbar {
                width: 0.2em;
                background: #e2e2ea;
                border-radius: 2.5px;
              }
              &::-webkit-scrollbar-thumb {
                background: #24325f8c;
                border-radius: 2.5px;
              }
              .MuiFormControlLabel-root {
                width: 100%;
              }
            }
          }
          .jd-checkbox-wrap {
            padding: 0 0.5em 1.5em 2em;
            .MuiFormGroup-root {
              height: 18.75em;
              overflow: auto;
              overflow-x: hidden;
              display: block;
              // flex-direction: row;
              &::-webkit-scrollbar {
                width: 0.2em;
                background: #e2e2ea;
                border-radius: 2.5px;
              }
              &::-webkit-scrollbar-thumb {
                background: #24325f8c;
                border-radius: 2.5px;
              }
              .MuiFormControlLabel-root {
                width: 32%;
                .Mui-checked {
                  color: #1e75fe;
                }
              }
            }
          }
          .fullBtnCancel {
            background-color: #ebebeb;
            margin-right: 0.5em;
          }
          .fullBtnSubmit {
            margin-left: 0.5em;
          }
          .job-category-tabel {
            border: 1px solid #9797972b;
            box-sizing: border-box;
            border-radius: 10px;
            height: 15.5em;
            overflow: auto;
            overflow-x: auto;
            margin-bottom: 2em;
            &::-webkit-scrollbar {
              width: 0.2em;
              background: #e2e2ea;
              border-radius: 2.5px;
            }
            &::-webkit-scrollbar-thumb {
              background: #24325f8c;
              border-radius: 2.5px;
            }
            &::-webkit-scrollbar:horizontal {
              height: 0.2em;
              background: #e2e2ea;
              border-radius: 2.5px;
            }
            &::-webkit-scrollbar-thumb:horizontal {
              background-color: #24325f8c;
              border-radius: 2.5px;
            }
            .job-category-tabel-head {
              position: sticky;
              top: 0;
              z-index: 1;
              .job-category-tabel-hrI {
                font-weight: 600;
                font-size: 11px;
                line-height: 16px;
                text-align: center;
                letter-spacing: 0.8px;
                text-transform: uppercase;
                color: #44444f;
                font-family: "Poppins-Regular";
                background: #dcecf5;
                border-radius: 10px 10px 0px 0px;
                margin: 0;
                padding: 1em;
                th {
                  font-weight: 600;
                  font-size: 11px;
                  line-height: 16px;
                  text-align: left;
                  letter-spacing: 0.8px;
                  text-transform: uppercase;
                  color: #44444f;
                  font-family: "Poppins-Regular";
                  background: #dcecf5;
                  margin: 0;
                  padding: 0.7em 1em;
                }
              }
              .job-category-tabel-hrII {
                background-color: #eeeeee;
                th {
                  font-weight: 600;
                  font-size: 11px;
                  line-height: 16px;
                  text-align: left;
                  letter-spacing: 0.8px;
                  text-transform: uppercase;
                  color: #44444f;
                  font-family: "Poppins-Regular";
                  margin: 0;
                  padding: 0.7em 1em;
                }
              }
            }
            .job-category-tabel-body {
              .dark-r {
                background-color: #0550c108;
              }
              .light-r {
                background-color: #ffffff;
              }
              .job-category-tabel-bcell {
                font-family: "Poppins-Regular";
                font-size: 0.875em;
                color: #44444f;
                border: none;
                .Mui-checked {
                  color: #1e75fe;
                }
                .MuiIconButton-root {
                  padding: 0;
                }
              }
            }
            .no-data-found-jd {
              width: 100%;
              height: 8em;
              display: flex;
              justify-content: center;
              align-items: center;
              margin: 0;
              font-family: "Poppins-Regular";
              font-size: 14px;
            }
          }
        }
      }
    }
  }
  .jd-loader-wrap {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .jd-loader {
      border: 4px solid #f3f3f3;
      border-radius: 50%;
      border-top: 4px solid #3498db;
      width: 40px;
      height: 40px;
      -webkit-animation: spin 2s linear infinite; /* Safari */
      animation: spin 2s linear infinite;
    }

    @-webkit-keyframes spin {
      0% {
        -webkit-transform: rotate(0deg);
      }
      100% {
        -webkit-transform: rotate(360deg);
      }
    }

    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
}
.jd-others-data {
  display: flex;
  align-items: center;
  svg {
    font-size: 1em;
  }
}

.formLabel-role {
  font-size: 1rem !important;
}

@media only screen and (max-width: 950px) {
  .job-description-modal {
    .job-descriptio-box {
      .job-desc-form {
        .form-data-wrap {
          .job-descriptio-step {
            padding: 0 1em;
            .job-desc-textLabel {
              font-size: 1.2em;
            }
            .jd-others-data svg {
              margin-left: 0.4em;
            }
            .jd-card {
              .jd-card-title {
                font-size: 1.2em;
              }
            }
            .jd-checkbox-wrap {
              .MuiFormGroup-root {
                .MuiFormControlLabel-root {
                  width: 47%;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 420px) {
  .job-description-modal {
    .job-descriptio-box {
      .job-desc-form {
        .form-data-wrap {
          .job-descriptio-step {
            .jd-checkbox-wrap {
              .MuiFormGroup-root {
                .MuiFormControlLabel-root {
                  width: 100%;
                  margin-right: 0;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 351px) and (max-width: 361px) {
  .formLabel-role {
    font-size: 0.9rem !important;
  }
}

.pageWrap {
  height: 100%;
  .topAppBar {
    z-index: 1;
    background-color: #ffffff;
  }
  .pageSection {
    position: relative;
    width: 100%;
    height: calc(100% - 4rem);
    padding-top: 4rem;
    .pageContentWrap {
      display: flex;
      height: 100%;
      .pageContent {
        width: 100%;
        position: relative;
        padding: 1.5rem;
        .headContainer {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          .leftItem {
            .pageHead {
              font-family: "Poppins-SemiBold";
              font-weight: 600;
              font-size: 24px;
              letter-spacing: inherit;
              color: #171725;
              margin: 0;
            }
            .pageHeadBreadCrumb,
            .pageHeadBreadCrumb a {
              font-family: "Poppins-Regular";
              font-size: 12px;
              line-height: 14px;
              color: #92929d;
              text-transform: capitalize;
              margin: 0;
            }
          }
          .rightItem {
            display: flex;
            .subItem {
              background-color: #ffffff;
              height: 2.125rem;
              border-radius: 0.6rem;
              display: flex;
              align-items: center;
            }
            .searchWrap {
              padding: 0 0.75rem;
              width: 10.3125rem;
              input {
                border: none;
                width: 100%;
                margin-left: 0.4rem;
                &:focus-visible {
                  border: none;
                  outline: unset;
                }
              }
            }
            .viewTypeWrap {
              align-items: center;
              margin: 0 1rem;
              padding-left: 0.75rem;
              width: 10.3125rem;
              label {
                margin-right: 0.4rem;
                font-family: "Poppins-Regular";
                font-size: 0.875rem;
                color: #696974;
                width: 32%;
              }
              .scoringSortWrap {
                background-color: #ffffff;
                color: #44444f;
                font-size: 0.875rem;
                width: 60%;
                font-family: "Poppins-SemiBold";
                height: 100%;
                &:before,
                &:after {
                  content: none;
                }
                > div {
                  padding: 0 24px 0 0;
                  height: 100%;
                  display: flex;
                  align-items: center;
                  &:focus {
                    background-color: transparent;
                    border: none;
                  }
                  &:focus-visible {
                    outline: none;
                  }
                }
                > input:focus-visible {
                  outline: none;
                }
                .MuiSelect-selectMenu {
                  padding: 6px 0 6px;
                }
                > svg {
                  height: 100%;
                  top: 0;
                  border-left: 1px solid #f1f1f5;
                }
              }
            }
            .filterWrap {
              width: 2.125rem;
              justify-content: center;
              align-items: center;
              cursor: pointer;
            }
          }
        }
        .bodyContainer {
          padding: 2em 0;
          .bodyCard {
            background: #ffffff;
            border-radius: 1.25rem;
            padding: 2rem;
            &.bodyCardMain {
              padding: 1rem !important;
            }
            .scoreTable {
              .tableNoBorder {
                border-color: transparent;
                padding: 0.7rem 1rem;
              }
              .shRow {
                background: rgba(168, 208, 231, 0.4);
                :first-child {
                  border-top-left-radius: 0.625rem;
                  border-bottom-left-radius: 0.625rem;
                }
                :last-child {
                  border-top-right-radius: 0.625rem;
                  border-bottom-right-radius: 0.625rem;
                }
                .sHCell {
                  color: #44444f;
                  font-size: 0.7875rem;
                }
              }
              .sRRow {
                text-align: center;
                cursor: pointer;
                font-family: "Poppins-Regular";
                &.evenRow {
                  background: #a8d0e71a;
                }
              }
              .sRCell {
                color: #171725;
                font-size: 0.875rem;
                font-family: "Poppins-Regular";
              }
            }
            .paginationWrap {
              display: flex;
              justify-content: flex-end;
              margin-top: 1rem;
              align-items: center;
              .paginationCountWrap {
                display: flex;
              }
              .rowCountWrap {
                display: flex;
                height: 2rem;
                align-items: center;
                justify-content: center;
                margin-left: 1rem;
                border: 1px solid #e2e2ea;
                border-radius: 8px;
                p {
                  margin: 0;
                  padding: 0.5rem 1rem;
                  border-right: 1px solid #e2e2ea;
                }
                .countHandler {
                  display: grid;
                  height: 100%;
                  width: 1rem;
                  .countUpArrowImgWrap {
                    height: 1rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-bottom: 1px solid #e2e2ea;
                    transform: translateY(-1px);
                  }
                  .countDownArrowImgWrap {
                    height: 1rem;
                    display: flex;
                    align-items: flex-end;
                    justify-content: center;
                    transform: translateY(-1px);
                  }
                }
              }
            }

            .canDetail {
              align-items: center;
              .canImg {
                width: 5rem;
                height: 5.6rem;
                object-fit: contain;
                img {
                  width: 100%;
                  height: 100%;
                  object-fit: contain;
                }
              }
              .canData {
                display: flex;
                justify-content: space-between;
                width: calc(100% - 5rem);
                padding: 0 2rem;
                .canItem {
                  .canTitle {
                    font-weight: 400;
                    font-size: 13px;
                    line-height: 16px;
                    letter-spacing: 0.1px;
                    color: #696974;
                    font-family: "Poppins-Regular";
                    margin: 0;
                  }
                  .canValue {
                    font-weight: 600;
                    font-size: 1.3rem;
                    line-height: 42px;
                    letter-spacing: 0.116667px;
                    color: #171725;
                    font-family: "Poppins-SemiBold";
                    margin: 0;
                  }
                }
              }
            }
            &.assessmentWrap {
              margin-top: 2rem;
              .asseHead {
                font-family: "Poppins-SemiBold";
                font-style: normal;
                font-weight: 600;
                font-size: 1.3rem;
                line-height: 27px;
                color: #171725;
                padding-bottom: 0.6rem;
              }
              .asseContent {
                display: flex;
                justify-content: space-between;
                align-items: center;
                border-top: 1px solid #f1f1f5;
                padding-bottom: 1rem;
                overflow-x: auto;
                &::-webkit-scrollbar {
                  height: 0.2em;
                  background: #e2e2ea;
                  border-radius: 2.5px;
                }
                &::-webkit-scrollbar-thumb {
                  background: #24325f8c;
                  border-radius: 2.5px;
                }
                .asseLeftContent {
                  width: 50%;
                  .asseLeftData {
                    font-family: "Poppins-Regular";
                    font-style: normal;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 19px;
                    letter-spacing: 0.1px;
                    color: #44444f;
                    text-transform: capitalize;
                  }
                }
                .asseRightContent {
                  display: flex;
                  width: 50%;
                  .asseRightContentItemI {
                    margin-right: 1rem;
                    width: 50%;
                  }
                  .asseRightContentItemII {
                    margin-right: 1rem;
                    width: 50%;
                    position: relative;
                  }
                  .asseRDataHead {
                    font-family: "Poppins-Regular";
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 16px;
                    text-align: left;
                    letter-spacing: 0.1px;
                    color: #696974;
                  }
                  .asseRDataValue {
                    font-family: "Poppins-SemiBold";
                    font-style: normal;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 24px;
                    text-align: left;
                    letter-spacing: 0.1px;
                    color: #3dd598;
                  }
                  .asseRDataValueA {
                    font-family: "Poppins-SemiBold";
                    font-style: normal;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 24px;
                    text-align: left;
                    letter-spacing: 0.1px;
                    color: #171725;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.interviewModal {
  .interviewWrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #ffffff;
    border-radius: 10px;
    width: 66%;
    height: auto;
    min-height: 55%;
    padding: 1rem 2rem 2rem;
    max-height: 88%;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 0.2em;
      background: #e2e2ea;
      border-radius: 2.5px;
    }
    &::-webkit-scrollbar-thumb {
      background: #24325f8c;
      border-radius: 2.5px;
    }
    .bodyCard {
      background: #fafafb;
      border-radius: 1.25rem;
      padding: 2rem;
      margin-bottom: 1rem;
      overflow-x: auto;

      .canDetail {
        align-items: center;
        .canImg {
          width: 5rem;
          height: 5.6rem;
          margin-bottom: 2rem;
          object-fit: contain;
          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
        .canData {
          display: flex;
          justify-content: space-between;
          width: calc(100% - 5rem);
          padding: 0 2rem;
          .canItem {
            font-family: "Poppins-Regular";
            .canTitle {
              margin: 0 0 0.625rem;
            }
            .canValue {
              font-size: 1.17rem;
              margin: 0;
            }
          }
        }
      }
      .scoreTable {
        .tableNoBorder {
          border-color: transparent;
          padding: 0.7rem 1rem;
        }
        .shRow {
          background: rgba(168, 208, 231, 0.4);
          :first-child {
            border-top-left-radius: 0.625rem;
            border-bottom-left-radius: 0.625rem;
          }
          :last-child {
            border-top-right-radius: 0.625rem;
            border-bottom-right-radius: 0.625rem;
          }
          .sHCell {
            color: #44444f;
            font-size: 0.7875rem;
          }
        }
        .sRRow {
          &.evenRow {
            background: #a8d0e71a;
          }
        }
        .sRCell {
          color: #171725;
          font-size: 0.875rem;
          font-family: "Poppins-Regular";
          &:last-child {
            text-align: left;
          }
          .tableInputF {
            width: 100%;
            background-color: #ffffff;
            border-radius: 14px;
            fieldset {
              border-radius: 14px !important;
            }
          }
          .rating {
            .MuiRating-root {
              color: #24325f;
            }
          }
        }
      }
    }
    .WrapAction {
      display: flex;
      .fullBtnReject {
        background-color: #fc5a5a;
        color: #fafafb;
        margin-right: 0.5rem;
      }
      .fullBtnAccept {
        background-color: #3dd598;
        color: #fafafb;
        margin-left: 0.5rem;
      }
    }
  }
}
.ratingBoxError {
  color: #f44336;
  margin: 0 0 2rem;
  font-size: 0.75rem;
  margin-top: 3px;
  text-align: center;
  font-family: Poppins-Regular;
  font-weight: 400;
  line-height: 1.5rem;
  letter-spacing: 0.03333rem;
  text-transform: none;
}

.jdPlaceholder {
  color: #c8c7cb;
}

@media only screen and (max-width: 960px) {
  .pageWrap {
    .pageSection {
      .pageContentWrap {
        .pageContent {
          .headContainer {
            display: block;
            .rightItem {
              margin-top: 1rem;
            }
          }
          .bodyContainer {
            .bodyCard {
              .canDetail {
                .canImg {
                  margin-bottom: 2rem;
                }
                .canData {
                  padding: 0rem;
                }
              }
            }
          }
        }
      }
    }
  }

  .interviewModal {
    .interviewWrapper {
      width: 88%;
      padding: 1rem 1rem 2rem;
      .bodyCard {
        padding: 1rem 0;
        .canDetail {
          .canImg {
            margin-bottom: 2rem;
          }
          .canData {
            padding: 0 1rem;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 620px) {
  .pageWrap {
    .pageSection {
      .pageContentWrap {
        .pageContent {
          padding: 1rem;
          .bodyContainer {
            padding: 1.2rem 0;
            .bodyCard {
              .scoreTable {
                font-size: 2rem;
              }
              .paginationWrap {
                display: block;
                .paginationCountWrap {
                  justify-content: center;
                  margin-top: 1rem;
                  nav {
                    button {
                      height: 2rem;
                      min-width: 2rem;
                      margin-bottom: 0.6rem;
                    }
                  }
                }
                .mobilePagination {
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                }
                .rowCountWrap {
                  height: 2.4rem;
                  width: 6rem;
                  .countHandler {
                    width: 2.7rem;
                    .countUpArrowImgWrap,
                    .countDownArrowImgWrap {
                      height: 1.1rem;
                    }
                  }
                }
              }
            }
          }
          .headContainer {
            .rightItem {
              .filterWrap {
                svg {
                  width: 1.2rem;
                }
              }
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 500px) {
  .interviewModal .interviewWrapper .bodyCard .scoreTable .sRCell .tableInputF {
    width: 15rem;
  }
}
@media only screen and (max-width: 378px) {
  .interviewModal {
    .interviewWrapper {
      .WrapAction {
        display: block;
        .fullBtnReject {
          width: 100%;
          margin-bottom: 1em;
          margin-right: 0;
        }
        .fullBtnAccept {
          width: 100%;
          margin-left: 0;
        }
      }
    }
  }
}

@media only screen and (max-width: 350px) {
  .pageWrap {
    .pageSection {
      .pageContentWrap {
        .pageContent {
          .bodyContainer {
            .bodyCard {
              .paginationWrap {
                .rowCountWrap {
                  height: 3.4rem;
                  width: 7rem;
                  p {
                    padding: 1rem 1.5rem;
                  }
                  .countHandler {
                    width: 2.9rem;
                    .countUpArrowImgWrap,
                    .countDownArrowImgWrap {
                      height: 1.5rem;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.ratingTooltip {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
}
